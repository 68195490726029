import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Card from "../Card/Card";
import { Button } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { allProducts } from "../Providers/Apicalls";
import { ApiEndPoints } from "../Providers/api_endpoints";

interface Product {
  _id?: string;
  name: string;
  image: string;
  category?: string;
  price?: number;
  isFreebie?: boolean;
  date?: string;
  description?: string;
  sizes?: string | null;
  stitchCount?: number;
  colors?: number;
  formats?: string;
  quantity?: string;
  buyersCount?: number;
  wishlistCount?: number;
  cartCount?: number;
  reviewsCount?: number;
  ratingsAverage?: number;
  isDeleted?: boolean;
  subimages?:[string]
}

const Bestselling = () => {

  

  const [ogData, setOgData] = useState<Product[]>([]);


  const getAllProduct = async () => {
      try {
        const firstResponse = await allProducts(ApiEndPoints("getAllProduct"));
        const newBestSellProduct = firstResponse.products.filter(
          (product: Product) =>
            product.isFreebie === false
        ).sort((a: Product, b: Product) => (b.buyersCount || 0) - (a.buyersCount || 0))
        .slice(0,4);
        setOgData(newBestSellProduct);
        console.log(newBestSellProduct,"newBestSellProduct");
        
      } catch (error) {
        console.error(error);
      }
    };

    useEffect(()=>{
      getAllProduct()
    },[])
  return (
    <>
      <section className="py-4" style={{ backgroundColor: "#FFCEDE" }}>
        <Container>
          <div className="position-relative">
            <h2 className="title">Best Selling</h2>
            <img
              src="/assets/headdecor.png"
              className="img-fluid best-sellingDecor"
            />
          </div>
          <Card data={ogData} />
        </Container>
      </section>
    
    </>
  );
};

export default Bestselling;
