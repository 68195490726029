import { Box, Button, Modal, Rating } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FaRegWindowClose, FaStar } from "react-icons/fa";
import { ApiEndPoints } from "../Providers/api_endpoints";
import { FaDownload } from "react-icons/fa6";

import {
  allProducts,
  forgetPass,
  getMe,
  patchCustom,
} from "../Providers/Apicalls";
import { API_URL } from "../Config";
import { message } from "antd";
import { FcOk } from "react-icons/fc";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};

const style2 = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
};

type UserData = {
  name: string;
  phone: number | null;
  email: string;
  role: string;
  orders: [];
  cart: [];
  wishlist: [];
  wallet: number | null;
  _id: string;
};

interface Product {
  _id?: string;
  name: string;
  image: string;
  category?: string;
  price?: number;
  isFreebie?: boolean;
  date?: string;
  description?: string;
  sizes?: string | null;
  stitchCount?: number;
  colors?: number;
  formats?: string;
  quantity?: string;
  buyersCount?: number;
  wishlistCount?: number;
  cartCount?: number;
  reviewsCount?: number;
  ratingsAverage?: number;
  isDeleted?: boolean;
  zip: string;
  subimages?:[string]
}

interface LineItem {
  product: string;
  amount: number;
}

interface Transaction {
  _id: string;
  lineItems: LineItem[];
  user: string;
  amount: number;
  date: string;
  couponCode: string | null;
  isPaid: boolean;
  isDeleted: boolean;
  hdfcOrderId: string | null;
}

interface customProduct {
  _id: string;
  image: string;
  category: string;
  user: string;
  quoteAmount: number | null;
  isPaid: boolean;
  date: string;
  description: string;
  size: string;
  fileFormat: string | null;
  isDeleted: boolean;
  name: string;
  zip: string;
}

interface OgData {
  products: Product[];
  transactions: Transaction[];
}

const Orders = () => {
  const [open, setOpen] = useState(false);
  const [ispayment, setIspayment] = useState(false);
  const [israting, setIsrating] = useState(false);
  const [iscustomRating, setIsCustomrating] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handlepaymentOpen = () => {
    setOpen(false);
    setIspayment(true);
  };
  const handlepaymentClose = () => setIspayment(false);
  const [value, setValue] = React.useState<number | null>(0);

  const [messageApi, contextHolder] = message.useMessage();

  const successmessage = (content: any) => {
    messageApi.open({
      type: "success",
      content: content,
      style: {
        fontSize: "16px",
        fontWeight: "bold",
      },
    });
  };

  const errormessage = (content: any) => {
    messageApi.open({
      type: "error",
      content: content,
      style: {
        fontSize: "16px",
        fontWeight: "bold",
      },
    });
  };

  const [userData, setUserData] = useState<UserData>({
    name: "",
    phone: null,
    email: "",
    role: "",
    orders: [],
    cart: [],
    wishlist: [],
    wallet: null,
    _id: "",
  });

  // const [ogData, setOgData] = useState<Product[]>([]);
  const [ogData, setOgData] = useState<OgData>({
    products: [],
    transactions: [],
  });

  const [customData, setCustomData] = useState<customProduct[]>([]);

  const getUser = async () => {
    try {
      const getMeuser = await getMe(ApiEndPoints("getMe"));
      setUserData(getMeuser.user);
      await getAlltransaction(getMeuser.user._id);
      await getAllCustomOrder(getMeuser.user._id);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  const getAlltransaction = async (id: string) => {
    try {
      // Fetch transactions
      const transactionsResponse = await allProducts(
        ApiEndPoints("getAlltransaction")
      );
      const userTransactions = transactionsResponse.transactions.filter(
        (item: Transaction) => item.user === id
      );


      // Extract all line item IDs from user transactions
      const lineItemIds = userTransactions.flatMap((transaction: Transaction) =>
        transaction.lineItems.map((lineItem) => lineItem.product)
      );

      // Fetch all products
      const productsResponse = await allProducts(ApiEndPoints("getAllProduct"));
      const allProductss = productsResponse.products;

      // Filter products that match the line item IDs
      const matchingProducts = allProductss.filter((product: Product) =>
        lineItemIds.includes(product._id || "")
      );

      // Set state with matching products and transaction amounts
      setOgData({
        products: matchingProducts,
        transactions: userTransactions,
      });

     
    } catch (error) {
      console.error(error);
    }
  };

  const handleDownload = async (zip: string) => {
    try {
      const response = await fetch(`${API_URL}/public/zips/${zip}`);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await response.blob();

      // Create a temporary URL for the blob
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = zip;
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };
const navigate=useNavigate()
  const handleorderDetail=(id:any)=>{
    navigate(`/order-detail/${id}`)
  }

  const renderProductRows = () => {
    // Filter products to include only those with an associated paid transaction
    const filteredProducts = ogData.products.filter((product: Product) => {
      return ogData.transactions.some(
        (transaction: Transaction) =>
          transaction.isPaid &&
          transaction.lineItems.some(
            (lineItem: LineItem) => lineItem.product === product._id
          )
      );
    });
  
 
  
    return filteredProducts.map((product: Product) => {
      // Find the corresponding transaction for this product
      const transaction = ogData.transactions.find((transaction: Transaction) =>
        transaction.isPaid &&
        transaction.lineItems.some(
          (lineItem: LineItem) => lineItem.product === product._id
        )
      );
  
      // Display the transaction amount if available, otherwise show '0.00'
      const displayAmount = transaction
        ? transaction.amount.toFixed(2)
        : "0.00";
  
      return (
        <tr className="order-row" key={product._id}>
          <td className="p-2">
            <img
              src={`${API_URL}/public/subimages/${product?.subimages?.[0]}`}
              className="img-fluid order-img"
              alt={product.name}
              onClick={() => handleorderDetail(transaction?._id)}
              style={{ cursor: "pointer" }}
            />
          </td>
          <td
            className="align-middle"
            onClick={() => handleorderDetail(transaction?._id)}
            style={{ cursor: "pointer" }}
          >
            {transaction?._id.slice(-5)}
          </td>
          <td
            className="align-middle"
            onClick={() => handleorderDetail(transaction?._id)}
            style={{ cursor: "pointer" }}
          >
            {transaction?.hdfcOrderId || transaction?._id.slice(-5)}
          </td>
          <td
            className="align-middle"
            onClick={() => handleorderDetail(transaction?._id)}
            style={{ cursor: "pointer" }}
          >
            <p className="mb-0" style={{ fontWeight: "600" }}>
              {product.name}
            </p>
            <p className="mb-0">{product.category}</p>
          </td>
          <td
            className="align-middle"
            onClick={() => handleorderDetail(transaction?._id)}
            style={{ cursor: "pointer" }}
          >
            ${displayAmount}
          </td>
          <td
            className="align-middle text-center"
            onClick={() => handleorderDetail(transaction?._id)}
            style={{ cursor: "pointer" }}
          >
            Done
          </td>
          <td className="align-middle text-center">
            <Button
              variant="contained"
              style={{height:"40px"}}
              className="download-order"
              onClick={() => handleDownload(product.zip)}
            >
        <FaDownload />

           
            </Button>
            <i
              className="fi fi-sr-file-download downloadFile"
              onClick={() => handleDownload(product.zip)}
            ></i>
          </td>
          <td className="align-middle text-center">
            <a
              onClick={() => handlegetProdID(product._id)}
              style={{ cursor: "pointer" }}
              className="rating-lg"
            >
              <FaStar style={{ color: "#D11243" }} /> Rate & Review Product
            </a>
            <a
              onClick={() => handlegetProdID(product._id)}
              style={{ cursor: "pointer" }}
              className="rating-sm"
            >
              <i className="fi fi-sr-feedback-alt ratingicon"></i>
            </a>
          </td>
        </tr>
      );
    });
  };
  

  const getAllCustomOrder = async (id: string) => {
    try {
      const response = await allProducts(ApiEndPoints("customorder"));
      // Filter transactions for the given user ID
      const userCustom = response.customOrders.filter(
        (item: Transaction) => item.user === id
      );
      setCustomData(userCustom);
    } catch (error) {
      console.error(error);
    }
  };
  const [data, setData] = useState({
    user: userData._id,
    product: "",
    rating: value,
    review: "",
  });

  const [dataCustom, setDataCustom] = useState({
    user: "",
    customOrder: "",
    rating: value,
    review: "",
  });

  const handlegetProdID = (prodID: any) => {
    setIsrating(true);
    setData({ ...data, product: prodID, user: userData._id });
  };

  const handlegetCustomID = (prodID: any) => {
    setIsCustomrating(true);
    setDataCustom({
      ...dataCustom,
      customOrder: prodID._id,
      user: prodID.user,
    });
  };

  //PostReview
  const handlepostReview = async () => {
    try {
      const result = await forgetPass(ApiEndPoints("postReview"), data);
      setIsrating(false);
      successmessage("Thanks for your review");
      setData({
        user: "",
        product: "",
        rating: value,
        review: "",
      });
      setIsrating(false);
    } catch (error: any) {
      console.log(error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errormessage(error.response.data.message);
      } else {
        errormessage("An unexpected error occurred. Please try again.");
      }
    }
  };

  //Post custom review
  const handlepostCustomReview = async () => {
    try {
      const result = await forgetPass(
        ApiEndPoints("postCustomreview"),
        dataCustom
      );
      setIsCustomrating(false);
      successmessage("Thanks for your review");
      setDataCustom({
        user: "",
        customOrder: "",
        rating: value,
        review: "",
      });
      setIsCustomrating(false);
    } catch (error: any) {
      console.log(error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errormessage(error.response.data.message);
      } else {
        errormessage("An unexpected error occurred. Please try again.");
      }
    }
  };

  const [loading, setLoading] = useState(false);
  const handlePayment = async (method: any) => {
    setLoading(true);
    const paypalScript = document.createElement("script");
    paypalScript.src = `https://www.paypal.com/sdk/js?client-id=AfZ_9fQMq1jEr_iccVNVnELcQw7rw1FDA5U0cKzASxTXQnSMHwoH2fqurRZ9M-nMFMVXIk04YriC-uJq`;
    // paypalScript.src = `https://www.paypal.com/sdk/js?client-id=AYRinc9e0YVy9jXQswETyVaRdvLDqVD59CgMUFigI37wwF_uiFXgTV0axLsBofJ_XlaSG2zujh4mH1py`; //Test
    paypalScript.onload = () => {
      window.paypal
        .Buttons({
          createOrder: function (data: any, actions: any) {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    currency_code: "USD",
                    value: method.quoteAmount,
                  },
                },
              ],
            });
          },
          onApprove: function (data: any, actions: any) {
            return actions.order.capture().then(async function (details: any) {
              successmessage(
                "Transaction completed by " + details.payer.name.given_name
              );
              await handleCheckout(method.quoteAmount, method._id);
            });
          },
          onError: function (err: any) {
            console.error("PayPal Checkout onError", err);
            errormessage("Payment failed, please try again.");
          },
        })
        .render("#paypal-button-container");
    };
    document.body.appendChild(paypalScript);
    setLoading(false);
  };

  const handleHDFCPayment = async (data: any) => {
    try {
      // Prepare the checkout data including user info, products, and coupon code
      const postData = {
        email: userData.email,
        userId: userData._id,
        customerName: userData.name,
        customOrder: data._id,
        products: [
          {
            name: data.category,
            price: customData[0].quoteAmount,
            product: data._id,
          },
        ],
        totalAmount: data.quoteAmount,
        date: new Date(),
        zipLinks: [],
      };
      const result = await forgetPass(ApiEndPoints("hdfcpayment"), postData);
      if (result.payment_links.web) {
        window.location.href = result.payment_links.web;
      }

      await getUser();
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errormessage(error.response.data.message);
      } else {
        errormessage("An unexpected error occurred. Please try again.");
      }
    }
  };

  const handleCheckout = async (amount: any, id: any) => {
    try {
      const postData = {
        paypalId: "done",
        customOrder: id,
        email: userData.email,
        customerName: userData.name,
        userId: userData._id,
        totalAmount: amount,
        amount: amount,
        products: [
          {
            name: "custom design",
            product: id,
            price: customData[0].quoteAmount,
          },
        ],

        zipLinks: [],
        date: Date.now(),
      };

      const result = await forgetPass(ApiEndPoints("checkout"), postData);

      // await capturepayment(result.id)
      await getUser();
      navigate("/my-orders")
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errormessage(error.response.data.message);
      } else {
        errormessage("An unexpected error occurred. Please try again.");
      }
    }
  };

  const capturepayment = async (id: any) => {
    console.log(id, "iddd");

    try {
      const res = await axios.post(
        `${API_URL}/api/payment/webhook?orderID=${id}`
      );
      await sendEmail();
      await handlePatchCustom(id);
    } catch (error: any) {
      console.log(error);

      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errormessage(error.response.data.message);
        console.log(error.response.data.message);
      } else {
        errormessage("An unexpected error occurred. Please try again.");
      }
    }
  };

  const sendEmail = async () => {
    try {
      // Prepare the checkout data including user info, products, and coupon code
      const postData = {
        amount: customData[0].quoteAmount, // The total amount of the order
        email: userData.email, // User's email
        customerName: userData.name, // Customer name
        products: [
          {
            name: "custom design",
            price: customData[0].quoteAmount,
          },
        ], // Products from the cart
        totalAmount: customData[0].quoteAmount, // Total amount formatted
        zipLinks: customData.map(
          (product) => `${API_URL}/public/zips/${product.zip}`
        ),
      };

      const result = await forgetPass(ApiEndPoints("sendemail"), postData);
      successmessage("Order Placed successfully");
      setIspayment(false);
      await getUser();
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errormessage(error.response.data.message);
      } else {
        errormessage("An unexpected error occurred. Please try again.");
      }
    }
  };

  const handlePatchCustom = async (id: any) => {
    const formData = new FormData();
    formData.append("isPaid", "true");
    try {
      const response = await patchCustom(
        ApiEndPoints("postCustomOrder"),
        id,
        formData
      );
      message.success("Payment updated!");
    } catch (err) {
      console.error("Error updating coupon:", err);
      message.error("An error occurred while updating the coupon.");
    }
  };

  return (
    <>
      {contextHolder}
      <section className="py-4" style={{ backgroundColor: "#FFCEDE" }}>
        <Container className="con"></Container>
        <div>
          <Container>
            <div className="position-relative">
              <h2 className="title">Customize Orders</h2>
              <img
                src="/assets/headdecor.png"
                className="img-fluid best-sellingDecor"
                alt="decor"
              />
            </div>
            <div style={{ overflowX: "auto" }}>
              {customData.length ? (
                <Table style={{ width: "100%" }}>
                  <thead>
                    <tr className="order-th">
                      <th></th>
                      {/* <th>ID</th> */}
                      <th>Product Name</th>
                      <th>Price</th>
                      <th className="text-center">Payment Status</th>
                      <th className="text-center">Download</th>
                      {/* <th className="text-center">Action</th> */}
                      <th className="text-center">Reviews & Ratings</th>
                    </tr>
                  </thead>
                  <tbody>
                    {customData.map((product) => (
                      <tr className="order-row" key={product._id}>
                        <td className="p-2">
                          <img
                            src={`${API_URL}/public/images/${product.image}`}
                            className="img-fluid order-img"
                          />
                        </td>
                        {/* <td className="align-middle">{product._id}</td> */}
                        <td className="align-middle">
                          <p className="mb-0" style={{ fontWeight: "600" }}>
                            {product.name}
                          </p>
                          <p className="mb-0">{product.category}</p>
                        </td>
                        <td className="align-middle">
                          {product.quoteAmount
                            ? `$ ${product.quoteAmount}`
                            : "-"}
                        </td>
                        <td className="align-middle text-center">
                          {product.quoteAmount ? (
                            product.isPaid ? (
                              <span style={{ color: "green" }}>Paid</span>
                            ) : (
                              <>
                                <>
                                  <Button
                                    style={{ color: "green", padding: "0px" }}
                                    onClick={() => handlePayment(product)}
                                  >
                                    Pay now using Paypal
                                  </Button>
                                  <div id="paypal-button-container"></div>
                                </>
                                <p className="mb-0">OR</p>
                                <div className="text-center pb-3">
                                  <Button
                                    className=" mb-0"
                                    style={{ color: "green", padding: "0px" }}
                                    onClick={() => handleHDFCPayment(product)}
                                  >
                                    Pay Now using HDFC
                                  </Button>
                                </div>
                              </>
                            )
                          ) : (
                            <span style={{ color: "rgb(209, 18, 67)" }}>
                              Pending
                            </span>
                          )}
                        </td>
                        <td className="align-middle text-center">
                          {product.isPaid ? (
                            <>
                              <Button
                                variant="contained"
                                className="download-order"
                                onClick={() => handleDownload(product.zip)}
                              >
                                {product.category}
                              </Button>
                              <i
                                className="fi fi-sr-file-download downloadFile"
                                onClick={() => handleDownload(product.zip)}
                              ></i>
                            </>
                          ) : (
                            "No file"
                          )}
                        </td>
                        {/* <td className="align-middle text-center">
                        <FcOk
                          style={{ fontSize: "22px", cursor: "pointer" }}
                          className="mx-1"
                        />
                        <FaRegWindowClose
                          className="mx-1"
                          style={{
                            fontSize: "20px",
                            color: "rgb(209, 18, 67)",
                            cursor: "pointer",
                          }}
                        />
                      </td> */}
                        {product.isPaid ? (
                          <td className="align-middle text-center">
                            
                            <a
                              onClick={() => handlegetCustomID(product)}
                              style={{ cursor: "pointer" }}
                              className="rating-lg"
                            >
                              <FaStar style={{ color: "#D11243" }} /> Rate &
                              Review Product
                            </a>
                            <a
                              onClick={() => handlegetCustomID(product._id)}
                              style={{ cursor: "pointer" }}
                              className="rating-sm"
                            >
                              <i className="fi fi-sr-feedback-alt ratingicon"></i>
                            </a>
                          </td>
                        ) : (
                          <td className="align-middle text-center">-</td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <h2 className="text-center">Oops no items found!</h2>
              )}
            </div>

            <div className="position-relative">
              <h2 className="title">My Orders</h2>
              <img
                src="/assets/headdecor.png"
                className="img-fluid best-sellingDecor"
                alt="decor"
              />
            </div>
            <div style={{ overflowX: "auto" }}>
              {ogData.products.length ? (
                <Table style={{ width: "100%" }}>
                  <thead>
                    <tr className="order-th">
                      <th></th>
                      <th>Order ID</th>
                      <th>Transcation ID</th>
                      <th>Product Name</th>
                      <th>Price</th>
                      <th className="text-center">Payment Status</th>
                      <th className="text-center">Download</th>
                      <th className="text-center">Reviews & Ratings</th>
                    </tr>
                  </thead>
                  <tbody>{renderProductRows()}</tbody>
                </Table>
              ) : (
                <h2 className="text-center">Oops no items found!</h2>
              )}
            </div>
            <Modal
              open={open}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <div style={{ borderRadius: "10px", overflow: "hidden" }}>
                  <div
                    className="d-flex justify-content-between align-items-center px-3 py-2"
                    style={{ backgroundColor: "#FFEDF3" }}
                  >
                    <div>
                      <h3 className="mb-0 review-head">Summary</h3>
                    </div>
                    <div>
                      <AiOutlineCloseCircle
                        className="close-icon"
                        onClick={handleClose}
                      />
                    </div>
                  </div>
                  <div className="px-4 pb-4">
                    <Table className="price-modal">
                      <tbody>
                        <tr>
                          <td>Price Details :</td>
                          <td className="text-end">$</td>
                        </tr>
                        <tr>
                          <td>Subtotal</td>
                          <td className="text-end">10.50</td>
                        </tr>
                        <tr>
                          <td>Tax</td>
                          <td className="text-end">5.0</td>
                        </tr>
                        <tr>
                          <td>Order Total</td>
                          <td className="text-end">$ 15.50</td>
                        </tr>
                      </tbody>
                    </Table>
                    <h4 className="coupon-head">Enter Discount Code</h4>
                    <div className="position-relative">
                      <input
                        placeholder="Coupon Code"
                        className="coupon-input"
                      />
                      <Button variant="contained" className="apply-btn">
                        Apply
                      </Button>
                    </div>
                    <div className="text-center pt-4">
                      <Button
                        variant="contained"
                        className="checkout-btn"
                        onClick={handlepaymentOpen}
                      >
                        Checkout
                      </Button>
                    </div>
                  </div>
                </div>
              </Box>
            </Modal>

            {/* Payment Modal */}
            <Modal
              open={ispayment}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <div style={{ borderRadius: "10px", overflow: "hidden" }}>
                  <div
                    className="d-flex justify-content-between align-items-center px-3 py-2"
                    style={{ backgroundColor: "#FFEDF3" }}
                  >
                    <div>
                      <h3 className="mb-0 review-head">Payment</h3>
                    </div>
                    <div>
                      <AiOutlineCloseCircle
                        className="close-icon"
                        onClick={handlepaymentClose}
                      />
                    </div>
                  </div>
                  <div className="px-4 pb-4">
                    <Table className="price-modal">
                      <tbody>
                        <tr>
                          <td>Amount :</td>
                          <td className="text-end">$</td>
                        </tr>
                        <tr>
                          <td>Order Total</td>
                          <td className="text-end">$ 15.50</td>
                        </tr>
                      </tbody>
                    </Table>
                    <h4 className="payment-head">Choose Payment Method</h4>
                    <div className="text-center">
                      <Button
                        variant="contained"
                        className="payment-btn"
                        onClick={handlePayment}
                      >
                        Pay Now
                      </Button>
                      <div className="px-3 pb-5">
                        <div
                          id="paypal-button-container"
                          className="paypal-div"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </Box>
            </Modal>

            {/* Rating Modal */}
            <Modal
              open={israting}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style2}>
                <div style={{ borderRadius: "10px", overflow: "hidden" }}>
                  <div
                    className="d-flex justify-content-between align-items-center px-3 py-2"
                    style={{ backgroundColor: "#FFEDF3" }}
                  >
                    <div>
                      <h3 className="mb-0 review-head">Rate & Review</h3>
                    </div>
                    <div>
                      <AiOutlineCloseCircle
                        className="close-icon"
                        onClick={() => setIsrating(false)}
                      />
                    </div>
                  </div>
                  <div className="px-4 pb-4">
                    <h4 className="review-head">Rate the Product</h4>
                    <Rating
                      name="simple-controlled"
                      value={data.rating}
                      onChange={(event, newValue) =>
                        setData({ ...data, rating: newValue })
                      }
                    />
                    <textarea
                      className="form-control p-2 mt-3"
                      rows={4}
                      placeholder="Write your review here"
                      onChange={(e) =>
                        setData({ ...data, review: e.target.value })
                      }
                    ></textarea>
                    <div className="text-center pt-4">
                      <Button
                        variant="contained"
                        className="checkout-btn"
                        onClick={() => handlepostReview()}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </Box>
            </Modal>

            {/* //Custom Modal */}
            <Modal
              open={iscustomRating}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style2}>
                <div style={{ borderRadius: "10px", overflow: "hidden" }}>
                  <div
                    className="d-flex justify-content-between align-items-center px-3 py-2"
                    style={{ backgroundColor: "#FFEDF3" }}
                  >
                    <div>
                      <h3 className="mb-0 review-head">Rate & Review</h3>
                    </div>
                    <div>
                      <AiOutlineCloseCircle
                        className="close-icon"
                        onClick={() => setIsCustomrating(false)}
                      />
                    </div>
                  </div>
                  <div className="px-4 pb-4">
                    <h4 className="review-head">Rate the Product</h4>
                    <Rating
                      name="simple-controlled"
                      value={dataCustom.rating}
                      onChange={(event, newValue) =>
                        setDataCustom({ ...dataCustom, rating: newValue })
                      }
                    />
                    <textarea
                      className="form-control p-2 mt-3"
                      rows={4}
                      placeholder="Write your review here"
                      onChange={(e) =>
                        setDataCustom({ ...dataCustom, review: e.target.value })
                      }
                    ></textarea>
                    <div className="text-center pt-4">
                      <Button
                        variant="contained"
                        className="checkout-btn"
                        onClick={() => handlepostCustomReview()}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </Box>
            </Modal>
          </Container>
        </div>
      </section>
    </>
  );
};

export default Orders;
