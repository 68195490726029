export const ApiEndPoints=(name:any)=> {
    var list :any={
        signup:"api/user/auth/signup",
        login:"api/user/auth/login",
        getMe: "api/user/getMe",
        forgetPass:"api/user/auth/forgotPassword",
        getAllProduct:"api/product",
        updateuser:"api/user",
        addtoWishlist:"api/user/wishlist",
        addtoCart:"api/user/cart",
        removeFromWishlist:"api/user/wishlist",
        removeFromCart:"api/user/cart",
        getbyslug: "api/product/slug",
        getreviewbyID:"api/review",
        postCustomOrder:"api/customOrder",
        customReviews:"api/customReview",
        checkout:"api/payment/checkout-session",
        getAlltransaction:"api/transaction",
        postReview:"api/review/",
        customorder:"api/customOrder",
        postCustomreview:"api/customReview/",
        resetPass:"api/user/auth/resetPassword",
        getAllcoupon:"api/coupon",
        getAllbanner:"api/heroPage",
        getCustombanner:"api/customBanner",
        resetPassword:"api/user/auth/resetPassword",
        sendemail:"api/payment/send-email",
        capturePayment:"api/payment/webhook",
        tag:"api/tag",
        hdfcpayment:"api/payment/initiateJuspayPayment",
        handleJuspayResponsebyID:"api/payment/handleJuspayResponsebyID",
        productTags:"api/productTag"
    };
    return list[name];
}