import { Button, Rating } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import type { UploadProps } from "antd";
import { message, Upload } from "antd";
import Bestselling from "../Home/Bestselling";
import { MdDriveFolderUpload } from "react-icons/md";
import { allProducts, forgetPass, postCustom } from "../Providers/Apicalls";
import { ApiEndPoints } from "../Providers/api_endpoints";
import { Helmet } from "react-helmet";

const { Dragger } = Upload;

const Customeview = () => {
  const [selectedSize, setSelectedSize] = useState<string>("");
  const [uploadedFile, setUploadedFile] = useState<any>(null);
  const [description, setDescription] = useState<string>("");
  const [reviews, setReviews] = useState<any[]>([]);
  const [averageRating, setAverageRating] = useState<number>(0);
  const [buyersCount, setBuyersCount] = useState<number>(0);

  const [messageApi, contextHolder] = message.useMessage();

  const successmessage = (content: string) => {
    messageApi.open({
      type: "success",
      content: content,
      style: {
        fontSize: "16px",
        fontWeight: "bold",
      },
    });
  };

  const errormessage = (content: string) => {
    messageApi.open({
      type: "error",
      content: content,
      style: {
        fontSize: "16px",
        fontWeight: "bold",
      },
    });
  };

  const getAllCustomreview = async () => {
    try {
      const firstResponse = await allProducts(ApiEndPoints("customReviews"));
      setReviews(firstResponse.customReviews);
      // Assuming the response has these properties
      setAverageRating(firstResponse.averageRating);
      setBuyersCount(firstResponse.buyersCount);
    } catch (error) {
      console.error(error);
    }
  };

  const handlepostCustom = async (e: React.FormEvent) => {
    e.preventDefault();

    const formData = new FormData();
    if (selectedSize) {
      formData.append("size", selectedSize);
    }
    if (description) {
      formData.append("description", description);
    }
    if (uploadedFile) {
      formData.append("image", uploadedFile);
      formData.append("category", "custom digitizing");
    }

    try {
      const result = await postCustom(
        ApiEndPoints("postCustomOrder"),
        formData
      );
      successmessage("Quote request sent successfully");
      setSelectedSize("");
      setDescription("");
      setUploadedFile(null);
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errormessage(error.response.data.message);
      } else {
        errormessage("An unexpected error occurred. Please try again.");
      }
    }
  };

  const props: UploadProps = {
    name: "file",
    beforeUpload: (file) => {
      setUploadedFile(file);
      return false; 
    },
    onRemove: () => {
      setUploadedFile(null);
    },
  };

  useEffect(() => {
    getAllCustomreview();
  }, []);

  return (
    <>
      {contextHolder}
      <Helmet>
        <title>
          Custom Digitizing Services | RG Embroidery Designs - Personalized
          Embroidery Solutions
        </title>
        <meta
          name="description"
          content="Get personalized embroidery solutions with our Custom Digitizing services at RG Embroidery Designs. Turn your ideas into high-quality embroidery patterns tailored to your needs. Contact us today for custom digitizing."
        />
      </Helmet>
      <section className="py-4" style={{ backgroundColor: "#FFCEDE" }}>
        <Container className="con">
          <div className="row">
            {/* Product image and details */}
            <div className="col-lg-5 col-md-5 col-sm-12 product-heigth">
              <div>
                <img
                  src="/assets/productview.png"
                  className="img-fluid embroidery-img"
                />
              </div>
            </div>
            <div className="col-lg-7 col-md-7 col-sm-12 product-heigth">
              <div className="embroidery-content py-3 px-4">
                <div className="d-flex justify-content-start align-items-center">
                  <h3 className="embroidery-title">Custom Digitizing</h3>
                </div>
                <div className="d-flex justify-content-start align-items-center flex-wrap">
                  <Rating name="read-only" value={averageRating} readOnly />
                  <p className="embroidery-rating mb-0">
                  {(averageRating || 0).toFixed()} ratings * {reviews.length} Reviews{" "}
                    <span className="embroidery-totalbuyers">
                      ( {buyersCount} Buyers)
                    </span>
                  </p>
                </div>
                <p className="embroidery-Category py-2">
                  Category: Custom Digitizing
                </p>
                <div>
                  <h4 className="coustom-subHead">Enter Customize Size:</h4>
                  <div className="d-flex justify-content-start align-items-center pb-3">
                    <div className="custom-dropdown">
                      <input
                        onChange={(e) => setSelectedSize(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="pb-3">
                  <h4 className="coustom-subHead">Upload Your Design</h4>
                  <div>
                    <Dragger {...props}>
                      <p className="ant-upload-drag-icon mb-0">
                        <MdDriveFolderUpload
                          style={{ color: "#D327A1", fontSize: "1.5rem" }}
                        />
                      </p>
                      <p className="ant-upload-text">
                        Click or drag file to this area to upload
                      </p>
                    </Dragger>
                  </div>
                </div>
                <div className="py-3">
                  <h4 className="coustom-subHead">
                    Leave a description of what is needed for your customized
                    Design:
                  </h4>
                  <textarea
                    className="textarea"
                    rows={4}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  ></textarea>
                  <Button
                    variant="contained"
                    className="button-medium"
                    onClick={handlepostCustom}
                  >
                    Send Quote
                  </Button>
                </div>
                <div className="product-description">
                  <h3>Custom Digitizing</h3>
                  <p>
                    RG Designs converts Digitizing the images, clip arts, arts
                    into machine embroidery files available for instant download
                    in multiple formats like DST, HUS, JEF, PES, XXX, VP3, VIP,
                    EXP. For those seeking something unique, we offer a
                    customization service. Simply submit your design and size
                    specifications using our online form, and we'll review your
                    request and send you a quote. Once you approve the quote and
                    make the payment, our team will craft your custom embroidery
                    design and deliver it to you in the specified digital
                    format. Enjoy seamless access to beautiful embroidery
                    designs tailored to your needs.
                  </p>
                </div>
                {reviews.length>0&&
                <div className="customer-reviews">
                  <h3>Reviews</h3>
                  {reviews.map((review) => (
                    <div key={review._id} className="review">
                      <h5>
                        {review.user.name}{" "}
                        <span className="review-date">
                          {new Date(review.date).toLocaleDateString()}
                        </span>
                      </h5>
                      <Rating name="read-only" value={review.rating} readOnly />
                      <p className="review-text">{review.review}</p>
                    </div>
                  ))}
                </div>}
                {/* <div className="text-center py-3">
                  <Button variant="contained" className="button-medium">
                    See More
                  </Button>
                </div> */}
              </div>
            </div>
          </div>
        </Container>
      </section>
      <Bestselling />
    </>
  );
};

export default Customeview;
