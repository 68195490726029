import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { RiArrowDownSFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { allProducts } from "../Providers/Apicalls";
import { ApiEndPoints } from "../Providers/api_endpoints";
import { log } from "console";

// Helper function to split array into chunks
const chunkArray = (arr: any[], chunkSize: number) => {
  const chunks = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    chunks.push(arr.slice(i, i + chunkSize));
  }
  return chunks;
};

const HeaderLg2 = () => {
  const [isDropdown, setIsdropdown] = useState(false);
  const [allTag, setAllTag] = useState<any[]>([]);
  const [tags, setTags] = useState<any>({
    tagName: "",
    tagValue: "",
  });

  const chunkedTags = chunkArray(tags, 10);

  const getAllTags = async () => {
    try {
      const response = await allProducts(ApiEndPoints("productTags"));
      setAllTag(response.productTags);

      const formattedTags = response.productTags.map(
        (tag:any) => ({
          value: tag._id,
          label: tag.tagName,
        })
      );
      setTags(formattedTags);
    } catch (error: any) {
      error.message(error.response.message);
    }
  };

  useEffect(() => {
    getAllTags();
  }, []);
  return (
    <>
      <section
        style={{
          backgroundColor: "#FFEDF3",
          position: "fixed",
          zIndex: 999,
          width: "100%",
          marginBottom: "2rem",
        }}
        className="py-3 d-none d-md-block"
      >
        <Container className="md-container">
          <div>
            <div>
              <div className="d-flex justify-content-around align-items-center mx-5 md-sub">
                <div>
                  <Link
                    to="/product-category/embroidery-designs"
                    className="navlink"
                  >
                    Embroidery Design
                  </Link>
                </div>
                <div>
                  <Link
                    to="/product-category/print-designs"
                    className="navlink"
                  >
                    Print Design
                  </Link>
                </div>
                <div>
                  <Link to="/custom-digitizing" className="navlink">
                    Custom Digitizing
                  </Link>
                </div>
                <div>
                  <Link to="/coupon" className="navlink">
                    Coupons
                  </Link>
                </div>
                <div className="position-relative">
                  <p
                    className="navlink"
                    onMouseEnter={() => setIsdropdown(true)}
                    onMouseLeave={() => setIsdropdown(true)}
                    style={{ cursor: "pointer" }}
                  >
                    Categories (Tags) <RiArrowDownSFill />
                  </p>
                  {isDropdown && (
                  <div
                    className="sub-navlinks"
                    onMouseEnter={() => setIsdropdown(true)}
                    onMouseLeave={() => setIsdropdown(false)}
                  >
                    {chunkedTags.map((tagChunk, index) => (
                      <div className="px-3" key={index}>
                        <ul className="p-0">
                          {tagChunk.map((val: any, i: any) => (
                            <li key={i}>
                              <Link to={`/product-tag/${val.label.toLowerCase()}`}>
                                {val.label}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                )}
                </div>
            
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default HeaderLg2;
