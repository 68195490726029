import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Card from "../Card/Card";
import { Dropdown, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Pagination } from "@mui/material";
import { ApiEndPoints } from "../Providers/api_endpoints";
import { allProducts } from "../Providers/Apicalls";
import { Helmet } from "react-helmet";

interface Product {
  _id?: string;
  name: string;
  image: string;
  category?: string;
  price?: number;
  isFreebie?: boolean;
  date?: string;
  description?: string;
  sizes?: string | null;
  stitchCount?: number;
  colors?: number;
  formats?: string;
  quantity?: string;
  buyersCount?: number;
  wishlistCount?: number;
  cartCount?: number;
  reviewsCount?: number;
  ratingsAverage?: number;
  isDeleted?: boolean;
  subimages?:[string]
}

const items: MenuProps["items"] = [
  {
    key: "1",
    label: <span>Sort By Popularity</span>,
  },
  {
    key: "2",
    label: <span>Sort By Average rating</span>,
  },
  {
    key: "3",
    label: <span>Sort By Least rating</span>,
  },
  {
    key: "4",
    label: <span>Sort By Price: Low to High</span>,
  },
  {
    key: "5",
    label: <span>Sort By Price: High to Low</span>,
  },
];

const ITEMS_PER_PAGE = 8;

const PrintDesign = () => {
  const [page, setPage] = useState(1);
  const [ogData, setOgData] = useState<Product[]>([]);
  const [sortedData, setSortedData] = useState<Product[]>([]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const getAllProduct = async () => {
    try {
      const firstResponse = await allProducts(ApiEndPoints("getAllProduct"));
      const printProduct = firstResponse.products.filter(
        (product: Product) =>
          product.category &&
          product.category.toLowerCase() === "print design" &&
          product.isFreebie === false
      );
      const reversedProducts = printProduct.reverse();
      setOgData(reversedProducts);
      setSortedData(reversedProducts);
    } catch (error) {
      console.error(error);
    }
  };

  const handleMenuClick: MenuProps["onClick"] = ({ key }) => {
    let sortedArray = [...ogData];
    switch (key) {
      case "1":
        sortedArray.sort((a, b) => (b.buyersCount || 0) - (a.buyersCount || 0));
        break;
      case "2":
        sortedArray.sort(
          (a, b) => (b.ratingsAverage || 0) - (a.ratingsAverage || 0)
        );
        break;
      case "3":
        sortedArray.sort(
          (a, b) => (a.ratingsAverage || 0) - (b.ratingsAverage || 0)
        );
        break;
      case "4":
        sortedArray.sort((a, b) => (a.price || 0) - (b.price || 0));
        break;
      case "5":
        sortedArray.sort((a, b) => (b.price || 0) - (a.price || 0));
        break;
    }
    setSortedData(sortedArray);
  };



  const paginatedData = sortedData.slice(
    (page - 1) * ITEMS_PER_PAGE,
    page * ITEMS_PER_PAGE
  );

  const startItem = (page - 1) * ITEMS_PER_PAGE + 1;
  const endItem = Math.min(page * ITEMS_PER_PAGE, sortedData.length);

  useEffect(() => {
    getAllProduct();
  }, []);

  return (
    <>
      <Helmet>
        <title>Print Design Embroidery | RG Embroidery Designs - Creative & Customizable Patterns</title>
        <meta
          name="description"
          content="Transform your projects with our Print Design embroidery patterns at RG Embroidery Designs. Discover creative and customizable designs perfect for printing on various fabrics and materials. Shop now for unique print designs."
        />
      </Helmet>
    <section className="py-4" style={{ backgroundColor: "#FFCEDE" }}>
        <Container className="con">
          <div className="d-flex justify-content-between align-items-center">
            <div className="position-relative">
              <h2 className="title">Print Design</h2>
              <img
                src="/assets/headdecor.png"
                className="img-fluid best-sellingDecor"
              />
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <p className="Embroideryview-count mb-0">
                Showing {startItem}-{endItem} of {sortedData.length} Results
              </p>
              <Dropdown
                menu={{ items, onClick: handleMenuClick }}
                className="sort-dropdown mx-2"
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Space>
                    Sort By
                    <DownOutlined />
                  </Space>
                </a>
              </Dropdown>
            </div>
          </div>
          <Card data={paginatedData} />
          <div className="d-flex justify-content-center mt-4">
            <Pagination
              count={Math.ceil(sortedData.length / ITEMS_PER_PAGE)}
              page={page}
              onChange={handleChange}
              color="primary"
            />
          </div>
        </Container>
      </section>
    </>
  );
};

export default PrintDesign;
