import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Card from "../Card/Card";
import { allProducts } from "../Providers/Apicalls";
import { ApiEndPoints } from "../Providers/api_endpoints";

interface Product {
  _id?: string;
  name: string;
  image: string;
  category?: string;
  price?: number;
  isFreebie?: boolean;
  date?: string;
  description?: string;
  sizes?: string | null;
  stitchCount?: number;
  colors?: number;
  formats?: string;
  quantity?: string;
  buyersCount?: number;
  wishlistCount?: number;
  cartCount?: number;
  reviewsCount?: number;
  ratingsAverage?: number;
  isDeleted?: boolean;
  subimages?:[string]
}

const Youmaylike = () => {
  const [ogData, setOgData] = useState<Product[]>([]);

  const getAllProduct = async () => {
    try {
      const firstResponse = await allProducts(ApiEndPoints("getAllProduct"));
      if (firstResponse && firstResponse.products && Array.isArray(firstResponse.products)) {
        // Filter the products where isFreebie is false
        const nonFreebieProducts = firstResponse.products.filter((product: Product) => !product.isFreebie);
  
        // Shuffle the filtered products array
        const shuffledProducts = nonFreebieProducts.sort(() => 0.5 - Math.random());
  
        // Select the first 4 items from the shuffled array
        const randomProducts = shuffledProducts.slice(0, 4);
        
        setOgData(randomProducts);
      } else {
        console.error("Unexpected data format:", firstResponse);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };
  

  useEffect(() => {
    getAllProduct();
  }, []);

  return (
    <section className="py-4" style={{ backgroundColor: "#FFCEDE" }}>
      <Container>
        <div className="position-relative">
          <h2 className="title">You May also like</h2>
          <img
            src="/assets/headdecor.png"
            className="img-fluid best-sellingDecor"
            alt="Head Decor"
          />
        </div>
        <Card data={ogData} />
      </Container>
    </section>
  );
};

export default Youmaylike;
