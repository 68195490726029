import { Box, Button, Modal } from '@mui/material'
import React, { useState } from 'react'
import { Container } from 'react-bootstrap'
import { GiCancel } from 'react-icons/gi'
import { Link, useNavigate } from 'react-router-dom'

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    borderRadius: 4,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

const Footer = () => {

    const [openSignup, setOpenSignup] = useState(false);
    const [openlogin, setOpenlogin] = useState(false);
    const [openforgetpass, setOpenforgetpass] = useState(false);
    const [openProfile, setOpenProfile] = useState(false);
  
    const handleSignupOpen = () => setOpenSignup(true);
    const handleSignupClose = () => setOpenSignup(false);

    const navigate=useNavigate()

    const handleNavHome=()=>{
        navigate("/")
    }


  
    const handleAlreadyuser = () => {
      setOpenSignup(false);
      setOpenlogin(true);
    };
  
    const handlenavcreateAccount = () => {
      setOpenlogin(false);
      setOpenSignup(true);
    };
  
    const handlenavForgetpass = () => {
      setOpenlogin(false);
      setOpenforgetpass(true);
    };
  
    const handleforgetpassCancel = () => {
      setOpenforgetpass(false);
      setOpenlogin(true);
    };

    const handleAccount=()=>{
        setOpenProfile(true)
    }

    const handleLogout=()=>{
        setOpenProfile(false)
    }


    return (
        <>
            <section className='py-4'>
                <Container>
                    <div className='row'>
                        <div className='col-lg-3 col-md-6 col-sm-12'>
                            <div>
                                <div>
                                    <img className='img-fluid footer-logo' src='/assets/logo-F.png' onClick={handleNavHome}/>
                                    <div className='d-flex justify-content-start align-items-center'>
                                        <div><img className='img-fluid mail-icon' src='/assets/media/mail.png'/></div>
                                        <div className='py-2'>
                                            <a href='mailto:info@rgembroiderydesigns.com' target='_blank' className='mail-id'>info@rgembroiderydesigns.com</a>
                                        </div>
                                    </div>
                                    <h4 className='follow py-2'>Follow Us On :</h4>
                                    <div className='d-flex justify-content-start align-items-center pb-2'>
                                        <div><a href='https://www.facebook.com/groups/rgdigitizing' target='_blank'><img className='img-fluid media-icon' src='/assets/media/facebook.png'/></a></div>
                                        <div className='mx-3'><a href='https://www.instagram.com/rgdigitizing/' target='_blank'><img className='img-fluid media-icon' src='/assets/media/insta.png'/></a></div>
                                        <div><a href='https://in.pinterest.com/rgdigitizing/_saved/' target='_blank'><img className='img-fluid media-icon' src='/assets/media/print.png'/></a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12'>
                            <div className='quick-links'>
                                <h3>INFORMATION</h3>
                                <ul className='px-0'>
                                    <li><Link to="/about-us">About Us</Link></li>
                                    <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                    <li><Link to="/delivery-information">Delivery Information</Link></li>
                                    <li><Link to="/return-refund-policy">Return & Refund Policies</Link></li>
                                    {/* <li><a onClick={handleNavHome} href='#faqs'>FAQs</a></li> */}
                                </ul>
                            </div>
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12'>
                            <div className='quick-links'>
                            <h3>CATEGORIES</h3>
                                <ul className='px-0'>
                                    <li><Link to="/freebies">Freebies</Link></li>
                                    <li><Link to="/coupon">Coupons</Link></li>
                                    <li><Link to="/product-category/print-designs">Print Design</Link></li>
                                    <li><Link to="/custom-digitizing">Custom Digitizing</Link></li>
                                    <li><Link to="/product-category/embroidery-designs">Embroidery Design</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12'>
                            <div className='quick-links'>
                            <h3>USER AREA</h3>
                                <ul className='px-0'>
                                    {/* <li><Link to="" onClick={handleAccount}>My Account</Link></li> */}
                                    <li><Link to="/my-orders">My Orders</Link></li>
                                    <li><Link to="/my-wishlist">Favorites</Link></li>
                                    {/* <li><Link to="" onClick={handleSignupOpen}>Login</Link></li> */}
                                    <li><Link to="/shopping-cart">Cart</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-12'>
                            <div className='quick-links'>
                                <h3>SUBSCRIBE</h3>
                                <p>To get discount and coupon card updates</p>
                                <div className='d-flex justify-content-start align-items-center'>
                                    <div><input type='email' placeholder='Enter Your Email Id'/></div>
                                    <div>
                                        <Button className='submit-btn' variant="contained">Submit</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        {/* Signup */}
            <Modal
              open={openSignup}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <div>
                  <div className="signupbox">
                    <div className="d-flex justify-content-between align-items-center pb-3">
                      <div>
                        <h3 className="mb-0 signup-Head">Create New Account</h3>
                      </div>
                      <div>
                        <GiCancel
                          style={{ color: "#D327A1", cursor: "pointer" }}
                          onClick={handleSignupClose}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="px-4">
                        <label className="py-2">First Name</label>
                        <br />
                        <input />
                        <br />
                        <label className="py-2">Last Name</label>
                        <br />
                        <input />
                        <br />
                        <label className="py-2">Email ID</label>
                        <br />
                        <input />
                        <br />
                        <label className="py-2">Phone Number</label>
                        <br />
                        <input />
                        <br />
                        <div className="text-center py-3">
                          <Button variant="contained">Create</Button>
                        </div>
                        <span>
                          Already Have an Account?
                          <a onClick={handleAlreadyuser}>Login here</a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Box>
            </Modal>

            {/* Login */}
            <Modal
              open={openlogin}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <div>
                  <div className="signupbox">
                    <div className="d-flex justify-content-between align-items-center pb-3">
                      <div>
                        <h3 className="mb-0 login-Head">Log In</h3>
                      </div>
                      <div>
                        <GiCancel
                          style={{ color: "#D327A1", cursor: "pointer" }}
                          onClick={() => setOpenlogin(false)}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="px-4">
                        <label className="py-2">Email ID</label>
                        <br />
                        <input />
                        <br />
                        <label className="py-2">Password</label>
                        <br />
                        <input />
                        <br />
                        <div className="text-center py-3">
                          <Button variant="contained">Sign In</Button>
                        </div>
                        <span>
                          New Customer?
                          <a onClick={handlenavcreateAccount}>
                            Create Your account
                          </a>
                        </span>
                        <br />
                        <span>
                          Forget password?
                          <a onClick={handlenavForgetpass}>Recovery password</a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Box>
            </Modal>

            {/* ForgetPassword */}
            <Modal
              open={openforgetpass}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <div>
                  <div className="signupbox">
                    <div className="d-flex justify-content-between align-items-center pb-3">
                      <div>
                        <h3 className="mb-0 signup-Head">Forgot Password</h3>
                      </div>
                      <div>
                        <GiCancel
                          style={{ color: "#D327A1", cursor: "pointer" }}
                          onClick={() => setOpenforgetpass(false)}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="px-4">
                        <label className="py-2">Email Address</label>
                        <br />
                        <input />
                        <br />
                        <div className="text-center py-3">
                          <Button variant="contained" className="mx-2">
                            Submit
                          </Button>
                          <Button
                            variant="contained"
                            className="mx-2"
                            onClick={handleforgetpassCancel}
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Box>
            </Modal>

            {/* Profile */}

            <Modal
              open={openProfile}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <div>
                  <div className="signupbox">
                    <div className="d-flex justify-content-between align-items-center pb-3">
                      <div>
                        <h3 className="mb-0 profile-Head">Profile</h3>
                      </div>
                      <div>
                        <GiCancel
                          style={{ color: "#D327A1", cursor: "pointer" }}
                          onClick={() => setOpenProfile(false)}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="px-4">
                        <table className="profile-table">
                          <tr>
                            <td>Name</td>
                            <td>Pravin</td>
                          </tr>
                          <tr>
                            <td>Mail Id</td>
                            <td>pravin@gmail.com</td>
                          </tr>
                          <tr>
                            <td>Phone Number</td>
                            <td>9600617877</td>
                          </tr>
                        </table>
                        <div className="text-center py-3">
                          <Button variant="contained" className="mx-2" onClick={handleLogout}>
                          Log Out
                          </Button>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Box>
            </Modal>
                </Container>
            </section>  
        </>
    )
}

export default Footer
