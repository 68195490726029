import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { getSlugProduct } from "../Providers/Apicalls";
import { ApiEndPoints } from "../Providers/api_endpoints";
import { Button } from "@mui/material";

const OrderDetails = () => {
  const { id } = useParams();
  const [data, setData] = useState<any>();

  const getTransaction = async () => {
    try {
      const transactionsResponse = await getSlugProduct(
        ApiEndPoints("getAlltransaction"),
        id
      );
      setData(transactionsResponse.transaction);
    } catch (error) {
      console.log(error);
    }
  };

  const downloadFile = (url: string) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = "";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    getTransaction();
  }, []);

  return (
    <>
      <section className="py-4" style={{ backgroundColor: "#FFCEDE" }}>
        <Container className="con">
          <div>
            <h2 className="title">Order details</h2>
          </div>
          <div
            style={{
              backgroundColor: "#fff",
              border: "1px solid #000",
              borderRadius: "10px",
              padding: "20px",
            }}
          >
            <table className="table-details">
              <tr>
                <th style={{fontWeight:500}}>Order ID </th>
                <th style={{fontWeight:500}}>: {data?._id.slice(-5)}</th>
              </tr>
              <tr>
                <th style={{fontWeight:500}}>Email Id</th>
                <th style={{fontWeight:500}}>: {data?.customerEmail}</th>
              </tr>
              <tr>
                <th style={{fontWeight:500}}>Payment Status</th>
                <th style={{fontWeight:500}}>: {data?.isPaid ? "Completed" : "Failed"}</th>
              </tr>
              <tr>
                <th style={{fontWeight:500}}>{data?.hdfcOrderId?"Transaction ID":"Order ID"}</th>
                <th style={{fontWeight:500}}>
                  : {data?.hdfcOrderId ? data.hdfcOrderId : data?._id.slice(-5)}
                </th>
              </tr>
            </table>
          </div>

          <div
            className="mt-3"
            style={{
              backgroundColor: "#fff",
              borderRadius: "10px",
              border: "1px solid #000",
              padding: "20px",
            }}
          >
            <table style={{ width: "100%" }}>
              <thead>
                <th>Product Name</th>
                <th>Product ID</th>
                <th>Amount</th>
                <th>Payment Status</th>
                <th>Download</th>
              </thead>
              <tr className="order-row">
                <td className="align-middle">
                  <p className="mb-0" style={{ fontWeight: "600" }}>
                    {data?.lineItems && data.lineItems.length > 0
                      ? data.lineItems[0].name
                      : "No items available"}
                  </p>
                </td>
                <td className="align-middle">
                  <p className="mb-0" style={{ fontWeight: "600" }}>
                    {data?.lineItems && data.lineItems.length > 0
                      ? data.lineItems[0].product.slice(-5)
                      : "No items available"}
                  </p>
                </td>
                <td className="">$ {data?.amount}</td>
                <td className="">Done</td>
                <td className="">
                  {data?.zipLinks && data.zipLinks.length > 0 ? (
                    <Button
                      variant="contained"
                      className="download-order"
                      onClick={() => downloadFile(data.zipLinks[0])}
                    >
                      {data?.lineItems && data.lineItems.length > 0
                        ? data.lineItems[0].name
                        : "Download"}
                    </Button>
                  ) : (
                    "No file available"
                  )}
                </td>
              </tr>
            </table>
          </div>
        </Container>
      </section>
    </>
  );
};

export default OrderDetails;
