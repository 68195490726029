import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { allProducts } from "../Providers/Apicalls";
import { ApiEndPoints } from "../Providers/api_endpoints";
import Card from "../Card/Card";
import { Container } from "react-bootstrap";
import { Button } from "@mui/material";
import Footer from "../Footer/Footer";

interface Product {
  _id?: string;
  name: string;
  image: string;
  category?: string;
  price?: number;
  isFreebie?: boolean;
  date?: string;
  description?: string;
  sizes?: string | null;
  stitchCount?: number;
  colors?: number;
  formats?: string;
  quantity?: string;
  buyersCount?: number;
  wishlistCount?: number;
  cartCount?: number;
  reviewsCount?: number;
  ratingsAverage?: number;
  isDeleted?: boolean;
  subimages?:[string]
}

const Search = () => {
  const [products, setProducts] = useState<any[]>([]);
  const location = useLocation();

  const getAllProduct = async () => {
    try {
      const firstResponse = await allProducts(ApiEndPoints("getAllProduct"));
      setProducts(firstResponse.products.filter((item:any)=>!item.isFreebie));
    } catch (error) {
      console.error(error);
    }
  };

  const queryParams = new URLSearchParams(location.search);
  const searchValue = queryParams.get("query") || "";

  const filteredProducts = products.filter(
    (product) =>
      product.name?.toLowerCase().includes(searchValue) ||
      product.description?.toLowerCase().includes(searchValue) ||
      product.metaContent?.toLowerCase().includes(searchValue) ||
      product.keywords.includes(searchValue) 
    );

  useEffect(() => {
    getAllProduct();
  }, []);

  return (
    <>
    <section className='pb-4' style={{backgroundColor:"#FFCEDE",paddingTop:"4rem"}}>
        <Container>
            <div className='position-relative'>
                <h2 className='title'>Search items</h2>
                <img src='/assets/headdecor.png' className='img-fluid best-sellingDecor'/>
            </div>
            {filteredProducts.length?
            <Card data={filteredProducts}/>:<h2 className="text-center">Oops no related items!</h2>}
            <div className='text-center pt-5 pb-2'><Link to="/all-design"><Button className='button-medium'  variant="contained">Explore More</Button></Link></div>
        </Container>
    </section>
    <Footer/>
</>
  );
};

export default Search;
