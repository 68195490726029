import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Card from '../Card/Card';
import { allProducts } from '../Providers/Apicalls';
import { ApiEndPoints } from '../Providers/api_endpoints';
import { Button } from '@mui/material';

interface Product {
  _id?: string;
  name: string;
  image: string;
  category?: string;
  price?: number;
  isFreebie?: boolean;
  date?: string;
  description?: string;
  sizes?: string | null;
  stitchCount?: number;
  colors?: number;
  formats?: string;
  quantity?: string;
  buyersCount?: number;
  wishlistCount?: number;
  cartCount?: number;
  reviewsCount?: number;
  ratingsAverage?: number;
  isDeleted?: boolean;
  subimages?:[string]
}

const RelatedProducts = () => {
  const [ogData, setOgData] = useState<Product[]>([]);
  const [visibleCount, setVisibleCount] = useState<number>(12); 

  const location = window.location.href;

  const getAllProduct = async () => {
    try {
      const firstResponse = await allProducts(ApiEndPoints("getAllProduct"));
      const newarriveProduct = firstResponse.products
        .filter((product: Product) =>
          product.category &&
          location.includes(product.category.replace(/\s+/g, '-'))
        );
      setOgData(newarriveProduct); 
    } catch (error) {
      console.error(error);
    }
  };

  // Load more products when "Explore More" is clicked
  const handleLoadMore = () => {
    setVisibleCount((prevCount) => prevCount + 12); 
  };

  useEffect(() => {
    getAllProduct();
  }, []);

  return (
    <>
      <section className='py-4' style={{ backgroundColor: "#FFCEDE" }}>
        <Container>
          <div className='position-relative'>
            <h2 className='title'>Related Products</h2>
            <img src='/assets/headdecor.png' className='img-fluid best-sellingDecor' />
          </div>
          {/* Show only the first `visibleCount` products */}
          <Card data={ogData.slice(0, visibleCount)} />
          {visibleCount < ogData.length && (
            <div className='text-center pt-3'>
              <Button className='button-medium' variant="contained" onClick={handleLoadMore}>
                Explore More
              </Button>
            </div>
          )}
        </Container>
      </section>
    </>
  );
};

export default RelatedProducts;
