import React, { useEffect } from 'react'
import Footer from '../Footer/Footer'
import OrderDetails from '../Myorders/OrderDetails';

const Orderdetail = () => {
    useEffect(()=>{
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      },[])
    return (
        <>
           <OrderDetails/>
           <Footer/> 
        </>
    )
}

export default Orderdetail
